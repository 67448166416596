import React, { Component } from 'react';
import Parse from 'parse';
import { Link, Redirect } from "react-router-dom";
import {getUrlVariable} from './utilities';
import ParseSortableList from './components/parseSortableList';

export default class EditLayers extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        layers: null
      })
  }
  componentDidMount= async()=>{
    let type = getUrlVariable('type')
    let parent = getUrlVariable('parent')
    var LayersQuery = Parse.Object.extend("Layers");
    var query = new Parse.Query(LayersQuery);
    query.equalTo("parent",parent);
    query.equalTo("type",type);
    query.ascending('order');
    query.limit(1000);
    let layers = await query.find()
    this.setState({
      layers:layers,
      parent:parent,
      type:type
    })
  }
  render() {
    if(!this.state.layers) return null;
    return (
      <div className="small-left-padding">
        <div>
          <div className="back-button">
            <Link to={'/editOption?id='+this.state.type+'&speakerId='+this.state.parent}>
              <img className="full-width" src="img/back.png" alt="back" />
            </Link>
          </div>
          <div className="">
            <h1 className="no-margin uppercase"> <h2>{'Reorder Layers'}</h2></h1>
          </div>
        </div>
        <ParseSortableList
          parseclass="Layers"
          parselist={this.state.layers}
          parselabelkey={'name'}
        />
      </div>
    )
  }
}

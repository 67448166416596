import React, { Component } from 'react';
import Parse from 'parse';
import { Link, Redirect } from "react-router-dom";
import {getUrlVariable} from './utilities';
import ParseEditableInput from './components/parseEditableInput';

export default class EditText extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  componentDidMount = async() =>{
    var id = this.state.id;
	  var Query = Parse.Object.extend("Text");
    var query = new Parse.Query(Query);
    let results = await query.find()
    let text = {}
    for (var i = 0; i < results.length; i++) {
      text[results[i].id] = results[i].attributes.value;
    }
    this.setState({
      text:text
    })
  }

  render() {
    if(!this.state.text) return null;
    return (
      <div className="small-left-padding">
        <div>
          <div className="back-button">
            <Link to={'/'}>
              <img className="full-width" src="img/back.png" alt="back" />
            </Link>
          </div>
          <div className="">
            <h1 className="no-margin uppercase">{'Edit Helper Text'}</h1>
          </div>
        </div>
        <h4>{'Led Core Not Selected Note'}</h4>
        <ParseEditableInput
          parseclass="Text"
          parseobjectid={'nl47YXFYhS'}
          parsekey="value"
          parsevalue={this.state.text['nl47YXFYhS']}
          placeholder="Write a comment..." />
      </div>
    )
  }
}

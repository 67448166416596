import Parse from 'parse';
import Axios from 'axios';

export const BuilderUrl = 'https://custom-shop.jlaudio.com/';

export const getUrlVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return (false);
}

export const updateParseObject = async (parseClass,parseObjectId,parseKey,parseValue) => {
  var Query = Parse.Object.extend(parseClass);
  var query = new Parse.Query(Query);
  let result = await query.get(parseObjectId)
  result.set(parseKey,parseValue)
  await result.save()
  return result
}

export const ConvertStringToTitleCase = (string) =>{
  if(!string) return string
  string = string.toLowerCase().split(' ');
  for (var i = 0; i < string.length; i++) {
    string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
  }
  return string.join(' ');
}

export const ConvertStringToSentenceCase = (string) =>{
  if(!string) return string
  var n=string.split(".");
  var vfinal="";
  for (var i = 0; i < n.length; i++) {
     var spaceput=""
     var spaceCount=n[i].replace(/^(\s*).*$/,"$1").length;
     n[i]=n[i].replace(/^\s+/,"");
     var newstring=n[i].charAt(n[i]).toUpperCase() + n[i].slice(1);
     for (var j = 0; j < spaceCount; j++) {
       spaceput=spaceput+" ";
       vfinal=vfinal+spaceput+newstring+".";
     }
   }
   vfinal=vfinal.substring(0, vfinal.length - 1);
   return vfinal
}

export const returnShopifyIdFromSku = async(sku) =>{
  let response = await Parse.Cloud.run('returnShopifyIdFromSku',{sku:sku})
  if(response){
    if(response.data){
      if(response.data.productVariants){
        if(response.data.productVariants.edges){
          if(response.data.productVariants.edges[0]){
            if(response.data.productVariants.edges[0].node){
              if(response.data.productVariants.edges[0].node.id){
                return(response.data.productVariants.edges[0].node.id.replace('gid://shopify/ProductVariant/',''))
              }
            }
          }
        }
      }
    }
  }
}


export const modelYear = "Products2019";

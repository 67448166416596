import React, { Component } from 'react';
import Parse from 'parse';
import {getUrlVariable,returnShopifyIdFromSku} from './utilities';

export default class AdminProcess extends Component {
  constructor(props) {
      super(props);
  }
  componentDidMount = async() =>{
    this.loopLayers();
  }

  loopLayers = async(layers) =>{
    var Query = Parse.Object.extend("Layers");
    var query = new Parse.Query(Query);
    query.limit(10000)
    query.find({
      success: async(layers) => {
        for (var i = 0; i < layers.length; i++) {
          this.updateVariantId(layers[i]);
        }
      }
    })
  }

  updateVariantId = async(layer) =>{
    let productNumber = layer.attributes.sku;
    if(productNumber){
      var Query = Parse.Object.extend("Layers");
      var query = new Parse.Query(Query);
      query.get(layer.id,{
        success: async(result) => {
          let shopifyId = await returnShopifyIdFromSku(productNumber)
          console.log(shopifyId)
          if(shopifyId){
            result.set('productNumber',shopifyId)
            //result.save();
            console.log('saved')
          }
        }
      })
    }
  }

  render() {
    return null;
  }
}

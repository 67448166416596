import React, { Component } from 'react';
import Parse from 'parse';
import HorizontalItemList from './components/horizontalItemList';
import { Link, Redirect } from "react-router-dom";
import {getUrlVariable} from './utilities';
import ParseEditableInput from './components/parseEditableInput';

export default class Layers extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        id: getUrlVariable('id')
      })
  }

  componentDidMount = async() =>{
      var Query = Parse.Object.extend("Speakers");
      var query = new Parse.Query(Query);
      let speaker = await query.get(this.state.id)
      this.setState({speaker:speaker})

      var Query = Parse.Object.extend("Layers");
      var query = new Parse.Query(Query);
      query.ascending("order");
      query.limit(1000);
      query.equalTo('parent',this.state.id)
      let results = await query.find()
      this.setUpLayers(results)
  }

  componentDidUpdate = async() =>{
      var Query = Parse.Object.extend("Layers");
      var query = new Parse.Query(Query);
      query.ascending("order");
      query.limit(1000);
      query.equalTo('parent',this.state.id)
      let results = await query.find()
      if(results != this.state.results){
        this.setUpLayers(results)
      }
  }

  setUpLayers = (results) =>{
    let emblems = results.filter((layer) => layer.attributes.type == "Emblem");
    let grills = results.filter((layer) => layer.attributes.type == "Grille");
    let trimRings = results.filter((layer) => layer.attributes.type == "Trim Ring");
    let ledLightColors = results.filter((layer) => layer.attributes.type == "Led Light Color");
    let speakerCores = results.filter((layer) => layer.attributes.type == "Speaker Core");
    this.setState({
      results:results,
      emblems:emblems,
      grills:grills,
      trimRings:trimRings,
      ledLightColors:ledLightColors,
      speakerCores:speakerCores
    })
  }
  render() {
    if(!this.state.speaker) return null;
    if(this.state.goBack) return <Redirect to={'/'} />
    return (
      <div className="small-left-padding">
        <div>
          <div className="back-button">
            <Link to={'/speakers'}>
              <img className="full-width" src="img/back.png" alt="back" />
            </Link>
          </div>
          <div className="medium-top-margin">
            <h1>Name</h1>
            <ParseEditableInput
              parseclass="Speakers"
              parseobjectid={this.state.speaker.id}
              parsekey="name"
              parsevalue={this.state.speaker.attributes.name}
              placeholder="Name of option" />
          </div>
        </div>
        <HorizontalItemList
          title={'Emblems'}
          parent={this.state.id}
          addLink={'/newLayer?type=Emblem&parent='+this.state.id}
          items={this.state.emblems}
          type={'Emblem'}
          editLink={'/editLayer'}
        />
        <HorizontalItemList
          title={'Grilles'}
          parent={this.state.id}
          addLink={'/newLayer?type=Grille&parent='+this.state.id}
          items={this.state.grills}
          type={'Grille'}
          editLink={'/editLayer'}
        />
        <HorizontalItemList
          title={'Trim Rings'}
          parent={this.state.id}
          addLink={'/newLayer?type=Trim Ring&parent='+this.state.id}
          items={this.state.trimRings}
          type={'Trim Ring'}
          editLink={'/editLayer'}
        />
        <HorizontalItemList
          title={'Led Light Color'}
          parent={this.state.id}
          addLink={'/newLayer?type=Led Light Color&parent='+this.state.id}
          items={this.state.ledLightColors}
          type={'Led Light Color'}
          editLink={'/editLayer'}
        />
        <HorizontalItemList
          title={'Speaker Cores'}
          parent={this.state.id}
          addLink={'/newLayer?type=Speaker Core&parent='+this.state.id}
          items={this.state.speakerCores}
          type={'Speaker Core'}
          editLink={'/editLayer'}
        />
        <div className="large-top-margin" style={{marginTop:200}}>
          <h2>{'Danger Zone (Cannot Be undone)'}</h2>
          <div onClick={this.delete} className="button red-background white">{'Delete This Speaker'}</div>
        </div>
      </div>
    )
  }
}

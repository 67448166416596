import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Parse from 'parse';
import { sortable } from 'react-sortable';
import {modelYear} from './../utilities';

export default class SortableList extends Component {
  state = {
    items: this.props.parselist,
		parseclass: this.props.parseclass
  };

  onSortItems = (items) => {
		var parseclass = this.state.parseclass;
		this.setState({
      items: items
    });
  }
  onSortEnd = async() => {
    this.setState({
      loading:true
    })
    var parseclass = this.state.parseclass;
    var items = this.state.items;
    items.map(async(item, i) => {
      var index = i;
      var Query = Parse.Object.extend(parseclass);
      var query = new Parse.Query(Query);
      let result = await query.get(item.id)
      result.set('order',index)
      result.save();
      if(i == items.length -1){
        this.setState({
          loading:false
        })
      }
    })
  }
  render() {
    const { items } = this.state;
    var listItems = items.map((item, i) => {
      var content = item.attributes[this.props.parselabelkey]
      if(this.props.onclickpath){
        var link = this.props.onclickpath+item[this.props.onclickkey];
        content = <NavLink to={link} className="black-color">
          {item.attributes[this.props.parselabelkey]}
        </NavLink>;
      }
      return (
        <SortableItem
          key={i}
          onSortItems={this.onSortItems}
          items={items}
          sortId={i}>
					<div>
						<div className="white-background small-padding small-bottom-margin">
							<div className="row">
								<img draggable="false" className="icon-20 small-right-margin" src="img/reorder-icon.png" alt="reorder" />
                {content}
							</div>
						</div>
					</div>
				</SortableItem>
      );
    });
    if(this.state.loading) return <div>saving</div>
    return (
      <div>
        <div className="button" onClick={this.onSortEnd}>{'Save Order'}</div>
        <div className='sortable-list'>
          {listItems}
        </div>
      </div>
    )
  }
};

class Item extends React.Component {
  render() {
    return (
      <div {...this.props}>
        {this.props.children}
      </div>
    )
  }
}


var SortableItem = sortable(Item);

import React, { Component } from 'react';
import Parse from 'parse';
import { Link, Redirect} from "react-router-dom";

export default class Login extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        username : null,
        password : null,
      })
  }
  parseLogin = async() => {
    let user = await Parse.User.logIn(this.state.username, this.state.password);
    if(user){
      window.location.assign('/')
    } else{
      alert('oops')
    }
  }
  usernameUpdate = (e) => {
    this.setState({username:e.target.value});
  }
  passwordUpdate = (e) => {
    this.setState({password:e.target.value});
  }

  render() {
    return (
      <div className="full-height full-width row dark-grey-background" style={{height:'100vh'}}>
        <div className="full-width" style={{margin:'auto', textAlign:"center", maxWidth:"600px"}}>
          <img className="three-quarter-width" src="img/logo.png" alt="Alpine" style={{maxWidth:'200px'}} />
          <h4 style={{color:'#fff'}}>{'Admin Login'}</h4>
          <div className="tiny-padding">
            <input
              type="text"
              value={this.state.username}
              onChange={this.usernameUpdate}
              placeholder={'Email'} />
          </div>
          <div className="tiny-padding">
            <input
              type="password"
              value={this.state.password}
              onChange={this.passwordUpdate}
              placeholder={'Password'} />
          </div>
          <div className="tiny-padding">
            <div className="button black-background white full-width" style={{margin:'0px'}} onClick={this.parseLogin}>Login</div>
          </div>
        </div>
      </div>
    )
  }
}

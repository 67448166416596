import React, { Component } from 'react';
import Parse from 'parse';
import { Link } from "react-router-dom";
import HorizontalItemList from './components/horizontalItemList';

export default class Speakers extends Component {
  constructor(props) {
      super(props);
      this.state = {list:[]}
  }

  componentDidMount = async() =>{
    var Query = Parse.Object.extend("Speakers");
    var query = new Parse.Query(Query);
    query.ascending("order");
    query.limit(1000);
    let results = await query.find()
    this.setState({
      speakers:results
    })
  }

  search = (value) =>{
    var results = this.state.fullResults;
    var filteredResults = [];
    if(value != ""){
      filteredResults = results.filter(result => result.attributes.name.toLowerCase().indexOf(value.toLowerCase()) != -1);
    } else{
      filteredResults = results
    }
    this.setState({
      speakers:filteredResults
    })
  }

  render() {
    return (
      <div className="small-left-padding">
        <h1 className="uppercase no-margin-top no-margin-bottom">{'Speakers'}</h1>
        <div className="row" style={{maxWidth:'500px', marginBottom:"60px"}}>
          <div className="small-12 medium-6 tiny-padding">
            <Link to={'/newSpeaker'}>
              <div className="button full-width primary-background white">{'Add New'}</div>
            </Link>
          </div>
        </div>
        <div className="row">
          {this.state.speakers && this.state.speakers.map((speaker) =>{
            let image = <img className="half-width light-grey-background" src={'/img/square.png'} />;
            if(speaker.attributes.image){
              image = <img className="half-width light-grey-background" src={speaker.attributes.image.url()} />;
            }
            return(
              <div className="small-12 medium-4 tiny-padding">
                <Link to={"/editSpeaker?id="+speaker.id}>
                  <div className="full-width white-background centered-text tiny-padding">
                    {image}
                    <h4 style={{color:"#000"}}>{speaker.attributes.name}</h4>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react';
import Parse from 'parse';
import { Link } from "react-router-dom";

export default class HorizontalItemList extends Component {
  constructor(props) {
      super(props);
      this.state = {}
  }


  render() {
    var addLink = this.props.addLink;
    return (
      <div>
        <div style={{marginTop:"10px",marginBottom:"10px"}}>
          <div style={{display:'flex', alignItems:'center'}}>
            <div className="row full-width">
              <div className="small-16 medium-6">
                <h2>{this.props.title}</h2>
              </div>
              {!this.props.hideReorder &&
                <div className="small-16 medium-6" style={{textAlign:"right"}}>
                  <Link to={'/reorderLayers?parent='+this.props.parent+'&type='+this.props.type}>{'Reorder'}</Link>
                </div>
              }
            </div>
            <div>
              <Link to={addLink}>
                <img style={{height:"30px",width:"30px", marginLeft:"20px"}} src="/img/add.png" />
              </Link>
            </div>
          </div>
        </div>
        <div style={{display:'flex', overflowX:'scroll'}}>
          {this.props.items && this.props.items.map((item,index) => {
            var thumb = '/images/placeholder.png';
            if(item.attributes.angle1){
              thumb = item.attributes.angle1.url();
            }
            return (
              <div className="list-tile" key={item.id}>
                <Link to={this.props.editLink+'?id='+item.id}>
                  {item.attributes.angle1 ?
                    <div>
                      <img className="background-image-fill full-width" src="img/square.png" alt="category" style={{backgroundImage:"url("+thumb+")"}} />
                      <div style={{paddingBottom:"20px"}}>
                        <h4 className="black-color full-width" style={{margin:"auto"}}>{item.attributes.name}</h4>
                      </div>
                    </div>
                    :
                    <div style={{position:'relative'}}>
                      <img className="background-image-fill full-width" src="img/square.png" alt="category" />
                      <div style={{display:"flex", alignItems:"center", justifyContent:"center", position:"absolute", top:0, left:0, right:0, bottom:0}}>
                        <h1 className="black-color full-width" style={{margin:"auto", paddingTop:"40px",paddingBottom:"40px",}}>{item.attributes.name}</h1>
                      </div>
                    </div>
                    }
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

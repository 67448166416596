import React, { Component } from 'react';
import Parse from 'parse';
import HorizontalItemList from './components/horizontalItemList';
import { Link, Redirect } from "react-router-dom";
import {getUrlVariable, BuilderUrl} from './utilities';
import ParseEditableInput from './components/parseEditableInput';
import ParseEditableImage from './components/parseEditableImage';

export default class EditSpeaker extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        id: getUrlVariable('id')
      })
  }

  componentDidMount = async() =>{
    var SpeakerQuery = Parse.Object.extend("Speakers");
    var speakerQuery = new Parse.Query(SpeakerQuery);
    let speaker = await speakerQuery.get(this.state.id)
    this.setState({speaker:speaker})

    var OptionQuery = Parse.Object.extend("Options");
    var optionQuery = new Parse.Query(OptionQuery);
    optionQuery.ascending('order');
    let options = await optionQuery.find()
    this.setState({options:options})
  }

  delete = async() =>{
    var Query = Parse.Object.extend("Speakers");
    var query = new Parse.Query(Query);
    let speaker = await query.get(this.state.id)
    await speaker.destroy()
    this.setState({
      goBack:true
    })
  }

  render() {
    if(!this.state.speaker) return null;
    if(this.state.goBack) return <Redirect to={'/'} />
    return (
      <div className="small-left-padding">
        <div>
          <div className="back-button">
            <Link to={'/speakers'}>
              <img className="full-width" src="img/back.png" alt="back" />
            </Link>
          </div>
          <a target="_blank" href={BuilderUrl+"?id="+this.state.speaker.id}>
            <div className="button primary-background white" style={{position:'absolute', top:'20px', right:'20px'}}>{'Preview'}</div>
          </a>
          <div className="medium-top-margin">
            <h1>Name</h1>
            <ParseEditableInput
              parseclass="Speakers"
              parseobjectid={this.state.speaker.id}
              parsekey="name"
              parsevalue={this.state.speaker.attributes.name}
              placeholder="Name of option" />
          </div>
          <div className="medium-top-margin">
            <div className="tiny-padding">
              <h2 style={{margin:"0px"}}>{'Image (Not customer facing)'}</h2>
            </div>
            <div style={{maxWidth:"200px"}}>
              <ParseEditableImage
                parseclass="Speakers"
                parseobjectid={this.state.speaker.id}
                parsekey="image"
                parsevalue={this.state.speaker.attributes.image}
              />
            </div>
          </div>
        </div>
        <div className="row" style={{marginTop:30}}>
          {this.state.options && this.state.options.map((option)=>{
            return (
              <div className="small-12 medium-2 tiny-padding">
                <Link to={"/editOption?id="+option.id+'&speakerId='+this.state.id}>
                  <div className="full-width white-background centered-text tiny-padding">
                    {option.attributes.image ?
                      <img className="full-width" src={option.attributes.image.url()} />
                      :
                      <img className="full-width light-grey-background" src={'/img/square.png'} />
                    }
                    <h2 style={{color:"#000"}}>{option.attributes.name}</h2>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
        <div className="large-top-margin" style={{marginTop:40}}>
          <h2>{'Danger Zone (Cannot Be Undone)'}</h2>
          <div onClick={this.delete} className="button red-background white">{'Delete This Speaker'}</div>
        </div>
      </div>
    )
  }
}

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Parse from 'parse';

export default class Header extends Component {

	logout = () =>{
		Parse.User.logOut().then(()=>{
			window.location.assign('/');
		});
	}
	render() {
		return (
			<div id="header">
				<div className="white-background">
					<div className="nav-header dark-grey-background">
						<div className="centered-text medium-padding">
							<NavLink to="/">
								<img className="three-quarter-width" src="img/logo.png" alt="Alpine" />
							</NavLink>
						</div>
					</div>
          <nav>
            <NavLink to="/">{'Speakers'}</NavLink>
						<NavLink to="/editText">{'Helper Text'}</NavLink>
						<div onClick={this.logout} style={{position:"absolute", bottom:"0px", left:"0px", margin:"0px"}} className="full-width button dark-grey-background white">{'Logout'}</div>
          </nav>
				</div>
			</div>
		)
	}
}

import React, { Component } from 'react';
import Parse from 'parse';
import './parseEditableImage.css';

export default class ParseEditableImage extends Component {
  constructor(props) {
      super(props);
      this.state={};
  }
  componentDidMount = () =>{
    this.updateData();
  }
  updateData = async() =>{
    var id = this.state.id;
    var Query = Parse.Object.extend(this.props.parseclass);
    var query = new Parse.Query(Query);
    let result = await query.get(this.props.parseobjectid)
    this.setState({
      result:result,
      uploading:false
    })
  }

  onPhotoAdded = async() =>{
    this.setState({
      uploading:true
    })
    var currentUser = Parse.User.current();
    var sessionToken = currentUser.get('sessionToken');
    console.log(sessionToken)
    var addPhoto = document.getElementById(this.props.parseclass+this.props.parsekey+"-photo");
    var parseFile = new Parse.File("photo.jpg", addPhoto.files[0]);
    await parseFile.save({sessionToken: sessionToken})
    var Query = Parse.Object.extend(this.props.parseclass);
    var query = new Parse.Query(Query);
    let data = await query.get(this.props.parseobjectid)
    if(parseFile){
      data.set(this.props.parsekey, parseFile);
    }
    let result = await data.save()
    this.updateData();
  }

  uploadClicked = () =>{
    document.getElementById(this.props.parseclass+this.props.parsekey+"-photo").click();
  }

  render() {
    if(!this.state.result) return null;
    var resizeMode = "background-image-fill"
    if(this.props.resizeMode == "fit"){
      resizeMode = "background-image-fit"
    }
    var ratio = '/img/square.png';
    if(this.props.ratio == 'rectangle'){
      ratio = '/img/rectangle.png';
    }
    if(this.props.ratio == 'sliver'){
      ratio = '/img/sliver.png';
    }
    var defaultImage = '/img/image-placeholder.png';
    if(this.state.uploading){
      defaultImage = '/img/loading.gif';
    }
    var imageTitle = 'Upload Image';
    if(this.state.imageTitle){
      defaultImage = imageTitle;
    }
    return (
      <div className="full-width image-placeholder-background image-upload-container background-image-fill" style={{backgroundImage:"url("+defaultImage+")"}}>
        {!this.state.uploading && this.state.result.attributes[this.props.parsekey] ?
          <img
            src={ratio}
            style={{'background-image':'url('+this.state.result.attributes[this.props.parsekey].url()+')',backgroundColor:"#fff"}}
            className={"full-width "+resizeMode}
          />
          :
          <img
            src={ratio}
            className={"full-width background-image-fit"}
          />
        }
        <div className="full-width image-upload-container-upload-button-container" onClick={this.uploadClicked}>
          <div className="button">{imageTitle}</div>
        </div>
        <input id={this.props.parseclass+this.props.parsekey+"-photo"} className="hidden" type="file" name="cover" onChange={this.onPhotoAdded} />
      </div>
    );
  }
}

import React, { Component } from 'react';
import Parse from 'parse';
import {Link, Redirect } from "react-router-dom";
import ParseEditableInput from './components/parseEditableInput'

export default class NewSpeaker extends Component {
  constructor(props) {
      super(props);
      this.state={};
  }

  componentDidMount = async() =>{
    var Query = Parse.Object.extend("Speakers");
    var query = new Parse.Query(Query);
    query.ascending("order");
    query.limit(1000);
    let speakers = await query.find()
    this.setState({
      speakers:speakers
    })
  }

  nameChange = (e) => {
    var value = e.target.value;
    this.setState({
      name:value
    })
  }

  slugChange = (e) => {
    var value = e.target.value;
    this.setState({
      slug:value
    })
  }

  save = async() => {
    var Query = Parse.Object.extend("Speakers");
    var item = new Query();
    item.set("slug",this.state.slug);
    item.set("name",this.state.name);
    let data = await item.save()
    if(this.state.template){
      var LayersQuery = Parse.Object.extend("Layers");
      var query = new Parse.Query(LayersQuery);
      query.equalTo("parent",this.state.template);
      query.limit(1000);
      let layers = await query.find()
      for (let i = 0; i < layers.length; i++) {
        var Layers = Parse.Object.extend("Layers");
        var layer = new Layers();
        layer.set('name',layers[i].attributes.name)
        layer.set('type',layers[i].attributes.type)
        layer.set('status',false)
        layer.set('color',layers[i].attributes.color)
        layer.set('colorSwatch',layers[i].attributes.colorSwatch)
        layer.set('parent',data.id)
        layer.save()
        if(i == layers.length -1){
          this.setState({
            goBack:true
          })
        }
      }
    } else{
      this.setState({
        goBack:true
      })
    }
  }
  render() {
    if(this.state.goBack) return <Redirect to={'/speakers'}/>
    return (
      <div className="small-left-padding">
        <div className="row">
          <div className="back-button">
            <Link to={'/speakers'}>
              <img className="full-width" src="img/back.png" />
            </Link>
          </div>
        </div>
        <div className="max-width-container medium-left-padding">
          <h1 className="no-margin uppercase">{'New Speaker'}</h1>
          <div className="medium-top-margin">
            <h2>Name</h2>
            <div className="tiny-padding">
              <input
                type="text"
                value={this.state.name}
                onChange={this.nameChange}
              placeholder="Name" />
            </div>
            <div className="tiny-padding">
              <h5>{'Create From Template'}</h5>
              <select onChange={(e)=> this.setState({template:e.target.value})}>
                <option value={null}>{'no template'}</option>
                {this.state.speakers && this.state.speakers.map((data)=>{
                  return <option value={data.id}>{data.attributes.name}</option>
                })}
              </select>
            </div>
            <div className="tiny-padding">
              <div className="button" onClick={this.save}>Save</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

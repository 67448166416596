import "./styles/grid.css";
import "./styles/spacing.css";
import "./styles/app.css";
import React from 'react';
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import createBrowserHistory from 'history/createBrowserHistory'
import App from './app';
import Parse from 'parse';

Parse.initialize("jlaudio", "D4oDFW1KY7oeqMaYLIbSQRj7YLw2Lxvw");
Parse.serverURL = 'https://alpine-jlaudio.herokuapp.com/api';

const history = createBrowserHistory();

const renderApp = Component =>
	render(
		<Router
			history={history}>
			<Component />
		</Router>,
		document.getElementById("root")
	);

renderApp(App);

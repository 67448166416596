import React, { Component } from 'react';
import Parse from 'parse';
import {Link, Redirect } from "react-router-dom";
import ParseEditableInput from './components/parseEditableInput';
import {getUrlVariable} from './utilities';

export default class NewLayer extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      type: getUrlVariable('type'),
      parent: getUrlVariable('parent')
    })
  }

  nameChange = (e) => {
    var value = e.target.value;
    this.setState({
      name:value
    })
  }

  save = async() => {
    var Query = Parse.Object.extend("Layers");
    var item = new Query();
    item.set("type",this.state.type);
    item.set("parent",this.state.parent);
    item.set("name",this.state.name);
    await item.save();
    this.setState({
      goBack:true
    })
  }

  render() {
    if(this.state.goBack) return <Redirect to={'/editOption?id='+this.state.type+'&speakerId='+this.state.parent}/>
    return (
      <div className="small-left-padding">
        <div className="row">
          <div className="back-button">
            <Link to={'/editOption?id='+this.state.type+'&speakerId='+this.state.parent}>
              <img className="full-width" src="img/back.png" />
            </Link>
          </div>
        </div>
        <div className="max-width-container medium-left-padding">
          <h1 className="no-margin uppercase">{'New Layer'}</h1>
          <div className="medium-top-margin">
            <h2>Name</h2>
            <div className="tiny-padding">
              <input
                type="text"
                value={this.state.name}
                onChange={this.nameChange}
              placeholder="Name" />
            </div>
            <div className="tiny-padding">
              <div className="button" onClick={this.save}>Save</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

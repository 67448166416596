import React, {Component} from 'react'
import { Route, Redirect, Switch } from "react-router-dom";
import Parse from 'parse';
import Header from "./components/header";
import Login from "./login";
import Speakers from "./speakers";
import NewSpeaker from "./newSpeaker";
import EditSpeaker from "./editSpeaker";
import EditText from "./editText";
import Layers from "./layers";
import NewLayer from "./newLayer";
import EditOption from "./editOption";
import EditLayer from "./editLayer";
import ReorderLayers from "./reorderLayers";
import AdminProcess from "./adminProcess";

export default class App extends Component {
  constructor() {
      super();
      this.state = {
          isAuthenticated:false
      };
  }

  isAuthenticated = () => {
    this.setState({isAuthenticated:true})
  }
  componentWillMount = () => {
    if(Parse.User.current()){
      this.setState({isAuthenticated:true})
    }
  }
  componentDidMount = () =>{

  }
  render() {
    return (
      <div className="wrapper">
        {window.location.pathname != '/login' && window.location.pathname != '/signup' && <Header brand={this.state.brand} />}
        <Switch>
  				<Route
  					render={({ location }) => (
              <div id="content" className="light-grey-background small-padding">
                  <Route exact path="/" render={() => {
                      return (
                        this.state.isAuthenticated ?
                        <Redirect to="/speakers" /> :
                        <Redirect to="/login" />
                      )
                  }}
                />
                <Route path="/editText" render={props => (<EditText />)} />
                <Route path="/speakers" render={props => (<Speakers />)} />
                <Route path="/newSpeaker" render={props => (<NewSpeaker />)} />
                <Route path="/editSpeaker" render={props => (<EditSpeaker />)} />
                <Route path="/editOption" render={props => (<EditOption />)} />
                <Route path="/layers" render={props => (<Layers />)} />
                <Route path="/newLayer" render={props => (<NewLayer />)} />
                <Route path="/editLayer" render={props => (<EditLayer />)} />
                <Route path="/reorderLayers" render={props => (<ReorderLayers />)} />
                <Route path="/login" render={props => (<Login />)} />
              </div>
  					)}
  				/>
        </Switch>
			</div>
		);
	}
}

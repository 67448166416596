import React, { Component } from 'react';
import Parse from 'parse';

export default class ParseEditableInput extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        value:this.props.parsevalue
      })
  }

  onChange = async (e) => {
    var parent = this;
    var parseclass = this.props.parseclass;
    var parseobjectid = this.props.parseobjectid;
    var parsekey = this.props.parsekey;
    var value = e.target.value;
    parent.setState({
      value:value
    })
    var Query = Parse.Object.extend(parseclass);
    var query = new Parse.Query(Query);
    let result = await query.get(parseobjectid)
    result.set(parsekey,value)
    await result.save();
  }

  render() {
    return (
      <div className="tiny-padding">
        <input
          type="text"
          value={this.state.value}
          onChange={this.onChange}
          placeholder="..." />
      </div>
    );
  }
}

import React, { Component } from 'react';
import Parse from 'parse';
import { Link, Redirect} from "react-router-dom";
import {getUrlVariable,returnShopifyIdFromSku} from './utilities';
import ParseEditableInput from './components/parseEditableInput';
import ParseEditableBoolean from './components/parseEditableBoolean';
import ParseEditableArray from './components/parseEditableArray';
import ParseEditableImage from './components/parseEditableImage';

export default class EditLayer extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        id: getUrlVariable('id'),
        updateKey: 1000
      })
  }
  componentDidMount = async() =>{
    var id = this.state.id;
	  var Query = Parse.Object.extend("Layers");
    var query = new Parse.Query(Query);
    let result = await query.get(id);
    this.setState({
      result:result
    })
  }
  delete = async() =>{
    var id = this.state.id;
	  var Query = Parse.Object.extend("Layers");
    var query = new Parse.Query(Query);
    let result = await query.get(id)
    await result.destroy()
    this.setState({
      goBack:true
    })
  }

  onHexColorChange = async(e) =>{
    let value = e.target.value;
    if(value && value.indexOf('#') == -1){
      value = '#'+value
    }
    var id = this.state.id;
	  var Query = Parse.Object.extend("Layers");
    var query = new Parse.Query(Query);
    let result = await query.get(id);
    await result.set('color',value)
    this.setState({
      result:result
    })
  }

  onSkuChange = async(e) =>{
    let value = e.target.value;
    var id = this.state.id;
    var Query = Parse.Object.extend("Layers");
    var query = new Parse.Query(Query);
    let result = await query.get(id)
    result.set('sku',value)
    await result.save()
    this.setState({
      result:result
    },async()=>{
      let shopifyId = await returnShopifyIdFromSku(value)
      console.log(shopifyId)
      if(shopifyId){
        result.set('productNumber',shopifyId)
        let prod = await result.save();
        this.setState({
          result:prod,
          updateKey:this.state.updateKey+1
        })
      }
    })
  }

  render() {
    if(!this.state.result) return null;
    if(this.state.goBack) return <Redirect to={'/editOption?id='+this.state.result.attributes.type+'&speakerId='+this.state.result.attributes.parent} />
    return (
      <div className="small-left-padding">
        <div>
          <div className="back-button">
            <Link to={'/editOption?id='+this.state.result.attributes.type+'&speakerId='+this.state.result.attributes.parent}>
              <img className="full-width" src="img/back.png" alt="back" />
            </Link>
          </div>
          <div className="">
            <h1 className="no-margin uppercase"> Edit {this.state.result.attributes.name}</h1>
          </div>
        </div>
        <div className="row">
          <div className="small-12 medium-5">
            <div style={{padding:"40px", paddingTop:"0px", maxWidth:"500px" }}>
              <div className="medium-top-margin">
                <h2>Angle 1</h2>
                <ParseEditableImage
                  parseclass="Layers"
                  parseobjectid={this.state.id}
                  parsekey="angle1"
                  parsevalue={this.state.result.attributes.angle1}
                />
              </div>
              <div className="medium-top-margin">
                <h2>Angle 2</h2>
                <ParseEditableImage
                  parseclass="Layers"
                  parseobjectid={this.state.id}
                  parsekey="angle2"
                  parsevalue={this.state.result.attributes.angle2}
                />
              </div>
            </div>
          </div>
          <div key={this.state.updateKey} className="small-12 medium-7">
            <div className="medium-top-margin">
              <h2>Name</h2>
              <ParseEditableInput
                parseclass="Layers"
                parseobjectid={this.state.id}
                parsekey="name"
                parsevalue={this.state.result.attributes.name}
                placeholder="Name of option" />
              </div>
              <div className="medium-top-margin">
                <h2>Product Sku</h2>
                <input
                  type="text"
                  value={this.state.result.attributes.sku}
                  onChange={this.onSkuChange}
                  placeholder="..." />
              </div>
              <div className="medium-top-margin">
                <h2>Shopify Product Id</h2>
                <ParseEditableInput
                  parseclass="Layers"
                  parseobjectid={this.state.id}
                  parsekey="productNumber"
                  parsevalue={this.state.result.attributes.productNumber}
                placeholder="1563456" />
              </div>
              <div className="medium-top-margin">
                <h2>Hex Color</h2>
                <div className="row">
                  <div className="small-2 medium-1" style={{backgroundColor:this.state.result.attributes.color}}></div>
                  <div className="small-2 medium-11" style={{paddingLeft:"10px"}}>
                    <input
                      type="text"
                      value={this.state.result.attributes.color}
                      onChange={this.onHexColorChange}
                      placeholder="..." />
                  </div>
                </div>
              </div>
              <div className="medium-top-margin">
                <h2>Color Image Swatch (optional)</h2>
                <div style={{maxWidth:"100px"}}>
                  <ParseEditableImage
                    parseclass="Layers"
                    parseobjectid={this.state.id}
                    parsekey="colorSwatch"
                    parsevalue={this.state.result.attributes.colorSwatch}
                  />
                </div>
              </div>
              <div className="medium-top-margin">
                <h2>Optional Note</h2>
                <ParseEditableInput
                  parseclass="Layers"
                  parseobjectid={this.state.id}
                  parsekey="note"
                  parsevalue={this.state.result.attributes.note}
                placeholder="Enter an optional note" />
              </div>
              <div className="medium-top-margin">
                <h2>Published</h2>
                <ParseEditableBoolean
                  parseclass="Layers"
                  parseobjectid={this.state.id}
                  parsekey="status"
                  parsevalue={this.state.result.attributes.status}
                />
              </div>
              <div className="medium-top-margin">
                <h2>Danger Zone</h2>
                <div onClick={this.delete} className="button red-background white">{'Delete'}</div>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

import React, { Component } from 'react';
import Parse from 'parse';
import HorizontalItemList from './components/horizontalItemList';
import { Link, Redirect } from "react-router-dom";
import {getUrlVariable} from './utilities';
import ParseEditableInput from './components/parseEditableInput';
import ParseEditableImage from './components/parseEditableImage';
import ParseEditableBoolean from './components/parseEditableBoolean';

export default class Layers extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        id: getUrlVariable('id'),
        speakerId:getUrlVariable('speakerId')
      })
  }

  componentDidMount = async() =>{
      var Query = Parse.Object.extend("Options");
      var query = new Parse.Query(Query);
      let option = await query.get(this.state.id)
      this.setState({option:option})

      var Query = Parse.Object.extend("Layers");
      var query = new Parse.Query(Query);
      query.ascending("order");
      query.limit(1000);
      query.equalTo('type',this.state.id)
      query.equalTo('parent',this.state.speakerId)
      let results = await query.find()
      this.setUpLayers(results)
  }

  setUpLayers = (results) =>{
    let option = decodeURI(getUrlVariable('id'));
    let layers = results.filter((layer) => layer.attributes.type == option);
    this.setState({
      layers:layers
    })
  }
  render() {
    if(!this.state.option) return null;
    if(this.state.goBack) return <Redirect to={'/editSpeaker?id='+this.state.speakerId} />
    return (
      <div className="small-left-padding">
        <div>
          <div className="back-button">
            <Link to={'/editSpeaker?id='+this.state.speakerId}>
              <img className="full-width" src="img/back.png" alt="back" />
            </Link>
          </div>
          <div className="medium-top-margin">
            <h1>{'Edit Option'}</h1>
          </div>
          <div className="medium-top-margin">
            <div className="tiny-padding">
              <h2 style={{margin:"0px"}}>{'Name'}</h2>
            </div>
            <ParseEditableInput
              parseclass="Options"
              parseobjectid={this.state.option.id}
              parsekey="name"
              parsevalue={this.state.option.attributes.name}
              placeholder="Name" />
          </div>
          <div className="medium-top-margin">
            <div className="tiny-padding">
              <h2 style={{margin:"0px"}}>{'Optional Note'}</h2>
            </div>
            <ParseEditableInput
              parseclass="Options"
              parseobjectid={this.state.option.id}
              parsekey="note"
              parsevalue={this.state.option.attributes.note}
              placeholder="Optional Note" />
          </div>
          <div className="medium-top-margin">
            <div className="tiny-padding">
              <h2 style={{margin:"0px"}}>{'Inventory Tracking'}</h2>
            </div>
            <ParseEditableBoolean
              parseclass="Options"
              parseobjectid={this.state.option.id}
              parsekey="inventoryTracking"
              parsevalue={this.state.option.attributes.inventoryTracking}
            />
          </div>
          <div className="medium-top-margin">
            <div className="tiny-padding">
              <h2 style={{margin:"0px"}}>{'Thumbnail Image'}</h2>
            </div>
            <div style={{maxWidth:"200px"}}>
              <ParseEditableImage
                parseclass="Options"
                parseobjectid={this.state.option.id}
                parsekey="image"
                parsevalue={this.state.option.attributes.image}
              />
            </div>
          </div>
        </div>
        <div className="medium-top-margin">
          <div className="tiny-padding">
            <h2 style={{margin:"0px"}}>{'Layers'}</h2>
          </div>
          <div className="row" style={{maxWidth:'500px'}}>
            <div className="small-12 medium-6 tiny-padding">
              <Link to={'/reorderLayers?type='+this.state.option.id+'&parent='+this.state.speakerId}>
                <div className="button full-width primary-background white">{'Reorder'}</div>
              </Link>
            </div>
            <div className="small-12 medium-6 tiny-padding">
              <Link to={'/newLayer?type='+this.state.option.id+'&parent='+this.state.speakerId}>
                <div className="button full-width primary-background white">{'Add New'}</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          {this.state.layers && this.state.layers.map((layer) =>{
            let image = <img className="full-width light-grey-background" src={'/img/square.png'} />;
            if(layer.attributes.angle1){
              image = <img className="full-width" src={layer.attributes.angle1.url()} />;
            }
            return(
              <div className="small-12 medium-3 tiny-padding">
                <Link to={"/editLayer?id="+layer.id}>
                  <div className="full-width white-background centered-text tiny-padding">
                    {image}
                    <h2 style={{color:"#000"}}>{layer.attributes.name}</h2>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
